var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "localNewsAndEventList" },
    [
      _c("news-and-event-list", {
        attrs: { context: _vm.context, branch: _vm.branch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }