var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasEventItems || _vm.hasNewsItems
    ? _c(
        "div",
        { staticClass: "newsAndEventList" },
        [
          _c("div", { staticClass: "newsAndEventList__grid" }, [
            _vm.hasNewsItems
              ? _c("div", { staticClass: "newsAndEventList__row" }, [
                  _c("h2", { staticClass: "newsAndEventList__headline" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("newsAndEvent.list.news.headline")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "newsAndEventList__list" },
                    _vm._l(_vm.limitNews, function (news) {
                      return _c(
                        "li",
                        { staticClass: "newsAndEventList__item" },
                        [_c("news-and-event-item", { attrs: { item: news } })],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasEventItems
              ? _c("div", { staticClass: "newsAndEventList__row" }, [
                  _c("h2", { staticClass: "newsAndEventList__headline" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("newsAndEvent.list.events.headline")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "newsAndEventList__list" },
                    _vm._l(_vm.limitEvents, function (event) {
                      return _c(
                        "li",
                        { staticClass: "newsAndEventList__item" },
                        [_c("news-and-event-item", { attrs: { item: event } })],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("btn", { attrs: { href: _vm.link } }, [
            _vm._v(_vm._s(_vm.$t("newsAndEvent.list.link"))),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }