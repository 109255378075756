
























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import NewsAndEventList from '../../../Component/NewsAndEvent/List/NewsAndEventList.vue';
import {Branch} from '../../../Interface/Branch';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';

export default {
    name: 'LocalNewsAndEvents',
    components: {NewsAndEventList},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        branch(): Branch
        {
            return BranchAndGeoLocationService.getCurrentBranch();
        }
    }
};
