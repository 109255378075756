
















































import Btn from '../../../Component/Misc/Btn/Btn.vue';
import NewsAndEventItem from '../../../Component/NewsAndEvent/Item/NewsAndEventItem.vue';
import LocalNewsAndEventsMixin from '../../../Mixin/LocalNewsAndEventsMixin';

export default {
    name: 'NewsAndEventList',
    components: {NewsAndEventItem, Btn},
    mixins: [LocalNewsAndEventsMixin],
    computed: {
        limitEvents()
        {
            const currentEvents = this.eventItems.filter((item) => {
                return new Date(item.date) >= new Date();
            });

            if (currentEvents.length > 0) {
                return currentEvents.reverse().slice(0, 4);
            }

            return this.eventItems.slice(0, 4);
        },
        limitNews()
        {
            return this.newsItems.slice(0, 4);
        }
    }
};
