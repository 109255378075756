/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.26 at 18:48
 */

import {isEmpty} from '@labor-digital/helferlein';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Collection} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {Branch} from '../Interface/Branch';
import {Event} from '../Interface/Event';
import {News} from '../Interface/News';

export default {
    props: {
        context: null as ContentElementContext,
        branch: null as Branch
    },
    data()
    {
        return {
            resultNews: [],
            resultEvents: []
        };
    },
    mounted()
    {
        let query = {
            filter: {},
            sort: ['-date']
        };

        if (this.branch) {
            query.filter['branch'] = this.branch.id;
        }

        this.context.resourceApi
            .getCollection('news', query)
            .then((response: Collection) => {
                this.resultNews = map(response.getAll(), (v) => v.getAll());
            });

        // Switch date for events
        this.context.resourceApi.getCollection('event', query)
            .then(result => {
                this.resultEvents = !isEmpty(result.response.data) ? result.response.data : [];
            });
    },
    computed: {
        newsItems(): Array<News>
        {
            return this.resultNews;
        },
        hasNewsItems(): boolean
        {
            return isArray(this.newsItems) && this.newsItems.length > 0;
        },
        eventItems(): Array<Event>
        {
            return this.resultEvents;
        },
        hasEventItems(): boolean
        {
            return isArray(this.eventItems) && this.eventItems.length > 0;
        },
        link()
        {
            return this.context.pageContext.linkRepository.get('newsAndEventList');
        }
    }
};
